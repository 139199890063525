import React, { useEffect, useState, useContext, useRef } from 'react'
import { Container, Row, Col, CarouselItem, Carousel, Form, FormGroup, Label, Input, FormFeedback, Navbar, Card, CardImg, Button } from 'reactstrap'
import '../../../CSS/HelpAndSupport.css'
import { globalState } from '../../../GlobalState/globalState';
import Search from '../../../../New_Website_V2/Assets/search.png'
import Mail from '../../../../New_Website_V2/Assets/mail.png'
import Call from '../../../../New_Website_V2/Assets/call.png'
import axios from 'axios'
import Down from '../../../Assets/hd.png'
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Up from '../../../../New_WebPage_Design/Assets/Commons/uparrow.png'
import RadioBtnCheck from '../../../Assets/radionButtonChk.png'
import RadioBtnUnCheck from '../../../Assets/radioButtonUnChk.png'
import InstaGramIcon from '../../../Assets/InstagramIcon.png'
import TwitterIcon from '../../../Assets/TwitterIcon.png'
import FacebookImage from '../../../Assets/FacebookIcon.png'
import PinterestImage from '../../../Assets/PinterestIcon.png'
import YoutubeIamge from '../../../Assets/YoutubeIcon.png'
import LinkedinImage from '../../../Assets/LinkedinIcon.png'
import CFAQ from '../../../../New_Website_V2/Screens/Help&Support/FaqConsumer_mobile'
import MFAQ from '../../../../New_Website_V2/Screens/Help&Support/FaqMerchant'
import { HashLink } from 'react-router-hash-link';

const RaiseComplain_pro_web = (route,navigation) => {
    const [activeIndex, setActiveIndex] = useState(0);
    let [state, dispatch] = useContext(globalState)
    const [user, setUser] = useState(true)
    const [pro, setPro] = useState(false)
    const [name, setName] = useState('')
    const [nameValid, setNamevalid] = useState(false)
    const [nameInvalid, setNameInvalid] = useState(false)
    const [email, setEmail] = useState('')
    const [emailValid, setEmailValid] = useState(false)
    const [emailInvalid, setEmailInvalid] = useState(false)
    const [mobile, setMobile] = useState('')
    const [mobileValid, setMobileValid] = useState(false)
    const [mobileInvalid, setMobileInvalid] = useState(false)
    const [subject, setSubject] = useState('')
    const [subjectValid, setSubjectValid] = useState(false)
    const [subjectInvalid, setSubjectInvalid] = useState(false)
    const [message, setMessage] = useState('')
    const [messageValid, setMessageValid] = useState(false)
    const [messageInvalid, setMessageInvalid] = useState(false)
    const [type, setType] = useState('')
    const [submit, setSubmit] = useState(false)
    const [reasonError, setReasonError] = useState('')
    const [submitSuccess, setSubmitSuccess] = useState(false)
    const [submitFail, setSubmitFail] = useState(false)
    const [loading, setLoading] = useState(false)
    const [down, setDown] = useState(true)
    const [up, setUp] = useState(false)
    const [helpType, setHelpType] = useState('consumer')
    const [consumer, setConsumer] = useState(true)
    const [merchant, setMerchant] = useState(false)
    const [show, setShow] = useState(false)
    const [control,setControl]=useState(false)
    const navigate = useNavigate();
    const params=useParams()
    const location = useLocation();
    const urlPath=location.pathname;

   useEffect(()=>{
    if(urlPath==='/FAQs'){
        // const goToViolation=(violationRef)=>{
        //     violationRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        // };
        // goToViolation(violationRef)

        const element2 = document.getElementById('faq');
        if (element2) {
          // 👇 Will scroll smoothly to the top of the next section
          element2.scrollIntoView({ behavior: 'smooth' });
        }
        
    }
    if(urlPath==='/contactus'){
        // const goToViolation=(violationRef)=>{
        //     violationRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        // };
        // goToViolation(violationRef)

        const element = document.getElementById('contactId');
        if (element) {
          // 👇 Will scroll smoothly to the top of the next section
          element.scrollIntoView({ behavior: 'smooth' });
        }

    } 

    if(urlPath == '/slashApp/helpAndSupport/complaint/consumer'){
        setMerchant(false)
        setConsumer(true)
        setHelpType('Consumer')
    } else if (urlPath == '/slashApp/helpAndSupport/complaint/merchant'){
        setMerchant(true)
        setConsumer(false)
        setHelpType('Merchant')
    }
        
    
     
      },[])
 
    let [Code, setCode] = useState(state.country.country === 'IN' ? '+91' : '+1')
    const [merchantReasons, setMerchantReasons] = useState(
        [
            'Business Hours', 'Calender', 'Coupons', 'Chat', 'Others', 'Payments', 'Profile', 'Referrals', 'Registration', 'Service'
        ],
    )
    const [consumerReasons, setConsumerReasons] = useState(
        [
            'Booking', 'Cancellation', 'Coupons', 'Chat', 'Others', 'Payments', 'Profile', 'Ratings', 'Referrals', 'Registration'
        ],
    )
    const [userOption, setUserOption] = useState(
        [
            'Customer’s FAQs'
            , 'Professional’s FAQs'
        ],
    )
    const [selectOption, setSelectOption] = useState('Customer’s FAQs')
   
    let MerchantItems = [
        {
            key: 1,
            src: {
                // web:NewImageMer,
                // mobile:NewImageMerMobile
            },
            altText: (
                <div>
                    <div className="altTextSv2">
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                                <div className='proNameStylesv2'>
                                    Help & Support
                                </div>
                            </div>
                            <div style={{ marginTop: 10, display: 'flex', flexDirection: 'row' }}>
                                <div className='addressTextsv2'>
                                    We are here to help you !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            ),
        }
    ]
    let MerchantSlides = MerchantItems.map((item) => {
        return (

            <CarouselItem
                // onExiting={this.onExiting}
                // onExited={this.onExited}
                key={item.key}
            >
                <div className="CarousalContainer_Sv2_HS" style={{ background: 'linear-gradient(to top, rgba(0, 0, 0) 100%, rgba(0, 0, 0) 0%)' }}>
                    {item.altText}

                    <img className='imgStylesv2_HS' src="https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684330440255-lg.png" />
                    {/* <img src={'https://slash-images.s3.us-west-2.amazonaws.com/Images_New/1666875231964-lg.jpg'} className='Carousal_policy_mobile'/> */}
                </div>
            </CarouselItem>

        );
    });


    const next = () => {
        // if (animating) return;
        const nextIndex = activeIndex === MerchantItems.length - 1 ? 0 : activeIndex + 1;
        setActiveIndex(nextIndex);
    };



    const previous = () => {
        // if (animating) return;
        const nextIndex = activeIndex === 0 ? MerchantItems.length - 1 : activeIndex - 1;
        setActiveIndex(nextIndex);
    };

    const nameHandler = (event) => {
        setSubmitSuccess(false)
        if (event.target.value === '' || event.target.value.length === 0) {
            setName('')
            setNamevalid(false)
            setNameInvalid(true)
            setSubmit(false)
        }
        else {
            setName(event.target.value)
            setNamevalid(true)
            setNameInvalid(false)
            setSubmit(true)
        }
    }
    const phoneHandler = (event) => {
        let k = /^[0-9\b]+$/;
        if (k.test(event.target.value)) {
            setMobile(event.target.value)
            setMobileInvalid(false)
            setMobileValid(true)
            setSubmit(true)
        }
        else {
            if (event.target.value.length === 0 || event.target.value === '') {
                setMobile(event.target.value)
                setMobileInvalid(true)
                setMobileValid(false)
                setSubmit(false)
            }
        }
    }
    const emailHandler = (event) => {
        let k = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (event.target.value.length === 0 || event.target.value === '') {
            setEmail('')
            setEmailInvalid(true)
            setEmailValid(false)
            setSubmit(false)
        }
        else {
            if (k.test(event.target.value)) {
                setEmail(event.target.value)
                setEmailInvalid(false)
                setEmailValid(true)
                setSubmit(true)

            } else {
                setEmail(event.target.value)
                setEmailInvalid(true)
                setEmailValid(false)
                setSubmit(false)
            }
        }
    }
    const contactReasonHandler = (event) => {
        if (event.target.value === '' || event.target.value === '--Select--') {
            setReasonError('Please Select Your Subject of Contact')
            setSubject(event.target.value)
            setSubjectInvalid(true)
            setSubjectValid(false)
            setSubmit(false)
        }
        else {
            setSubject(event.target.value)
            setSubjectInvalid(false)
            setSubjectValid(true)
            setSubmit(true)
        }
    }
    const userHandler = (event) => {

        setShow(!show)
        setControl(true)
        if(up){
            setDown(false)
        }
        else{
            setUp(false)
        }


    }
    const messageHandler = (event) => {
        if (event.target.value === '') {
            setMessage('')
            setMessageInvalid(true)
            setMessageValid(false)
            setSubmit(false)
        }
        else {
            setMessage(event.target.value)
            setMessageInvalid(false)
            setMessageValid(true)
            setSubmit(true)
        }
        // console.log(this.state.textAreaCount,'count')

    }

    const submitHandler = (e) => {
        // console.log('email', email, emailInvalid, emailValid)
        let k = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (name.length === 0 || name === '' || nameInvalid) {
            setName(name)
            setNamevalid(false)
            setNameInvalid(true)
            setSubmit(false)
        }
        else if (email.length === 0 || emailInvalid || !(k.test(email))) {
            // console.log('email', email)
            setEmail(email)
            setEmailValid(false)
            setEmailInvalid(true)
            setSubmit(false)
        }
        else if (mobileInvalid || mobile.length === 0) {
            if (!(/^[0-9\b]+$/.test(mobile))) {
                setMobile(mobile)
                setMobileInvalid(true)
                setMobileValid(false)
                setSubmit(false)
            }
        }
        else if (subjectInvalid || subject.length === 0 || subject === '') {
            setSubject(subject)
            setSubjectInvalid(true)
            setSubjectValid(false)
            setSubmit(false)
        }
        else if (message === '' || message.length === 0) {
            setMessage(message)
            setMessageInvalid(true)
            setMessageValid(false)
            setSubmit(false)


        }
        else {
            // this.setState({
            //     name: {
            //         ...this.state.name,
            //         valid: true,
            //         invalid: false
            //     },
            //     mobileNumber: {
            //         ...this.state.mobileNumber,
            //         valid: true,
            //         invalid: false
            //     },
            //     email: {
            //         ...this.state.email,
            //         valid: true,
            //         invalid: false
            //     },
            //     contactReason: {
            //         ...this.state.contactReason,
            //         valid: true,
            //         invalid: false
            //     },
            //     messageType: {
            //         ...this.state.messageType,
            //         valid: true,
            //         invalid: false
            //     },
            //     submit: {
            //         invalid: false
            //     }
            // })
            setName(name)
            setNameInvalid(false)
            setNamevalid(true)

            setEmail(email)
            setEmailInvalid(false)
            setEmailValid(true)

            setMessage(message)
            setMessageInvalid(false)
            setMessageValid(true)

            setSubject(subject)
            setSubjectInvalid(false)
            setSubjectValid(true)

            setMobile(mobile)
            setMobileInvalid(false)
            setMobileValid(true)
            setSubject(true)
            let body = {
                country: Code ? Code : null,
                name: name,
                mobile: mobile,
                email: email,
                contactReason: subject,
                messageType: message,
                helpType: helpType
            }

            // console.log('submiting', body)
            axios.post('/website/helpAndSupport/leads', body)
                .then(res => {

                    if (res.data.data) {
                        // console.log('res', res.data.data)
                        setName('')
                        setNameInvalid(false)
                        setNamevalid(false)
                        setCode('')

                        setEmail('')
                        setEmailInvalid(false)
                        setEmailValid(false)

                        setMessage('')
                        setMessageInvalid(false)
                        setMessageValid(false)

                        setSubject('')
                        setSubjectInvalid(false)
                        setSubjectValid(false)

                        setMobile('')
                        setMobileInvalid(false)
                        setMobileValid(false)

                        setSubmitSuccess(true)
                        setSubmitFail(false)

                    }
                    else {
                        if (res.data.dbError) {
                            setSubmitSuccess(false)
                            setSubmitFail(true)
                            setLoading(false)

                        }
                        else {
                            setSubmitSuccess(false)
                            setSubmitFail(true)
                            setLoading(false)
                        }
                    }
                })
                .catch(error => {
                    // this.setState({
                    //     ...this.state,
                    //     submitSuccess: false,
                    //     submitFail: true,
                    //     loading: false
                    // })
                    setSubmitSuccess(false)
                    setSubmitFail(true)
                    setLoading(false)
                })
        }
    }


    const FaqHandler = (event) => {
        // console.log('mechat valie', event.target.value)
        if (event.target.value == 'Consumer') {
            // console.log('true Consumer')
            setUser(true)
            setConsumer(true)
            setMerchant(false)
            setPro(false)
        }
        else if (event.target.value == 'Merchant') {
            setMerchant(true)
            setConsumer(false)
            setUser(false)
            setPro(true)
        }
    }
    const helpHandler1 = () => {
        setHelpType('Consumer')
        setSelectOption('Customer’s FAQs')
        setConsumer(true)
        setMerchant(false)
        setUser(true)
        setPro(false)
        setSubject('')
        setShow(false)


    }
    const helpHandler2 = () => {
        setHelpType('Merchant')
        setSelectOption('Professional’s FAQs')
        setMerchant(true)
        setPro(true)
        setUser(false)
        setConsumer(false)
        setSubject('')
        setShow(false)



    }
    const codeHandler1 = (e) => {
        // console.log('value', e.target.value)
        setCode(e.target.value)
        if (e.target.value === '+91') {
            setCode(e.target.value)

        }
        else {
            setCode(e.target.value)


        }
    }
const reasonDiv =()=>{
    setUp(!up)
    if(down){
        setUp(true)
    }
    else{
        setDown(true)
    }
}

    const downHandler = () => {
        setDown(false)
        setUp(true)

    }
    const upHandler = () => {
        setDown(true)
        setUp(false)

    }
    const subjectSelectHandler = (item) => {
        setSubject(item)
        setUp(false)
        setDown(true)

    }
    const userSelectHandler = (item) => {
        // console.log('userOption', item, show)

        console.log('log', show)
        if (item === 'Customer’s FAQs') {
            setControl(true)
            setUser(true)
            setConsumer(true)
            setMerchant(false)
            setPro(false)
            setShow(false)
            // console.log(show)
            setSelectOption(item)

        }
        if (item === 'Professional’s FAQs') {
            setMerchant(true)
            setConsumer(false)
            setUser(false)
            setPro(true)
            setShow(false)
            setSelectOption(item)
            setControl(true)
        }

    }
    let userOptions;
    if (userOption.length !== 0) {
        // console.log('dff')
        userOptions = userOption.map((option3, index) => {
            return (
                <div className='subjectDiv1' onClick={() => userSelectHandler(option3)}>
                    <text className='subjectText1' key={index}>{option3}</text>
                </div>

            )

            // return <option value={option1.id} key={option1.id}>{option1.name}</option>
        })
    }
    let consumerReasonOptions;
    let merchantReasonOptions;
    if (consumerReasons.length !== 0) {
        consumerReasonOptions = consumerReasons.map((option1, index) => {
            return (
                <div className='subjectDiv' onClick={() => subjectSelectHandler(option1)}>
                    <text className='subjectText' key={index}>{option1}</text>
                </div>

            )

            // return <option value={option1.id} key={option1.id}>{option1.name}</option>
        })
    }
    if (merchantReasons.length !== 0) {
        merchantReasonOptions = merchantReasons.map((option2, index) => {
            // return <option value={option2} key={index}>{option2}</option>
            return (
                <div className='subjectDiv' onClick={() => subjectSelectHandler(option2)}>
                    <text className='subjectText' key={index}>{option2}</text>
                </div>

            )
            // return <option value={option2.id} key={option2.id}>{option2.name}</option>
        })
    }
    // const violationRef = useRef();
    // console.log('navparems',urlPath)
         
    
    const preventCopyPaste = (e) => {
        e.preventDefault()
        // toast.info("Copying and pasting is not allowed!", {position: toast.POSITION.BOTTOM_CENTER})
      }
  
  

    return (
        <div>
            {/* <div id={'contactId'}>
                <Carousel
                    activeIndex={activeIndex}
                    next={next}
                    previous={previous}
                    ride="carousel"
                    interval={2000}
                >
                    {MerchantSlides}
                </Carousel>
            </div> */}

            <div className='HandS_Container_Home'>
                <div  >

                    <div className='headder'><text>Want to reach out to us?</text></div>
                    <div className='subheadder'><text>Can’t find the answer you are looking for?</text></div>
                    <hr />
                    <div className='wantToReachOut'>

                        <div className='contact_View'>
                            <div>
                                <img src="https://qf-test-media.s3.us-west-2.amazonaws.com/Images_New/1684330459090-lg.png" className='imgMain' />
                            </div>
                            {/* contact div */}
                            <div className='contact_Option'>
                                <div className='textContect' >
                                    <text style={{ textAlign: 'initial' }}>Contact details</text>
                                </div>
                                <div className='call'>
                                    <img src={Call} className='imgWidth' />
                                    {state.country.country === 'IN' ?
                                        <text style={{ textAlign: 'initial' }}>+91 8977509988</text>
                                        :
                                        <text style={{ textAlign: 'initial' }}>+1 650-739-8103</text>
                                    }

                                </div>
                                <div className='search'>
                                    <img src={Search} className='imgWidth' />
                                    <text style={{ textAlign: 'initial' }}>www.goslash.com</text>
                                </div>
                                <div className='mail'>
                                    <img src={Mail} className='imgWidth' />
                                    <text style={{ textAlign: 'initial' }}>{merchant ? 'msupport@goslash.com' : 'support@goslash.com' }</text>
                                </div>
                                <div className='socialMedia'>
                                    <img src={InstaGramIcon} style={{ cursor: 'pointer',width:20,height:20 }} onClick={() => { window.open('https://www.instagram.com/goslash.app/') }} />
                                    <img src={TwitterIcon} style={{ marginLeft: 20, cursor: 'pointer' ,width:20,height:20}} onClick={() => { window.open('https://twitter.com/goslashapp') }} />
                                    <img src={FacebookImage} style={{ marginLeft: 20, cursor: 'pointer',width:20,height:20 }} onClick={() => { window.open('https://www.facebook.com/goslashapp/') }} />
                                    <img src={PinterestImage} style={{ marginLeft: 20, cursor: 'pointer',width:20,height:20 }} onClick={() => { window.open('https://in.pinterest.com/goslashapp/') }} />
                                    <img src={YoutubeIamge} style={{ marginLeft: 20, cursor: 'pointer',width:20,height:20 }} onClick={() => { window.open('https://www.youtube.com/channel/UCNIC3KzckSXejaMtWbRHA2Q') }} />
                                    <img src={LinkedinImage} style={{ marginLeft: 20, cursor: 'pointer' ,width:20,height:20}} onClick={() => { window.open('https://www.linkedin.com/company/goslashapp') }} />
                                </div>
                            </div>
                        </div>

                        <div className='contactFiled1' >

                            <div className='userOptions1'>
                                {/* <div style={{ flexDirection: 'row', display: 'flex' }} > */}
                                    {/* <Input type="radio" style={{ marginRight: 20, cursor: 'pointer' }} checked={consumer} onClick={helpHandler1} /> */}

                                    {/* <div onClick={helpHandler1} >
                                        {consumer ? <img src={RadioBtnCheck} style={{ width: 20, height: 20 }} /> : <img src={RadioBtnUnCheck} style={{ width: 16, height: 16 }} />}
                                    </div>
                                    <div>
                                        <text className='optionText' style={{ color: '#8c52ff' }}>I’m a Customer</text>
                                    </div>
                                </div> */}
                                <div style={{flexDirection: 'row', display: 'flex' }}>
                                    {/* <Input type="radio" sty
                                    le={{ marginRight: 20, cursor: 'pointer' }} checked={merchant} onClick={helpHandler2} /> */}
                                    <div onClick={helpHandler2}>
                                        {merchant ? <img src={RadioBtnCheck} style={{ width: 20, height: 20 }} /> : <img src={RadioBtnUnCheck} style={{ width: 16, height: 16 }} />}
                                    </div>

                                    <div>
                                        <text className='optionText' style={{ color: '#8c52ff' }}>I’m a Professional</text>
                                    </div>
                                </div>
                            </div>

                            <div className='formHeadder1' style={{ paddingTop: 15 }}><text>Send us a Message</text></div>
                            <Form className='fromLeft'>
                                {submitSuccess === true ? <text style={{ color: '#18A558', fontWeight: 'bold', paddingLeft: 10 }}>Your Details Are Submitted Successfully!</text> : submitFail === true ? <text style={{ color: '#DC143C' }}>Something Went Wrong,Please Try Again Later!</text> : null}

                                <FormGroup className='Form-label-text1'>
                                    <Input
                                        style={{ color: '#8c52ff' }}
                                        onChange={nameHandler}
                                        valid={nameValid ? true : false}
                                        invalid={nameInvalid ? true : false}
                                        placeholder="Name"
                                        value={name}
                                        type="text"
                                        onPaste={(e) => preventCopyPaste(e)}
                                        onCopy={(e) => preventCopyPaste(e)}
                                        maxLength={40} />
                                    <FormFeedback>Name is invalid!</FormFeedback>
                                </FormGroup>


                                <FormGroup className='Form-label-text1'>
                                    <Input
                                        style={{ color: '#8c52ff' }}
                                        onChange={emailHandler}
                                        valid={emailValid ? true : false}
                                        invalid={emailInvalid ? true : false}
                                        placeholder="Email Address"
                                        value={email}
                                        type="text"
                                        onPaste={(e) => preventCopyPaste(e)}
                                        onCopy={(e) => preventCopyPaste(e)}
                                        maxLength={40} />
                                    <FormFeedback>Email is invalid!</FormFeedback>
                                </FormGroup>
                                <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'space-between' }}>

                                    <div style={{ flexDirection: 'row', display: 'flex' }}>
                                        <Input
                                            onChange={codeHandler1}
                                            className='codeHS'
                                            // valid={this.state.Code.valid ? true : false}
                                            // invalid={this.state.Code.invalid ? true : false}
                                            value={Code}
                                            type="select">
                                            <option value='+1'>+1</option>
                                            <option value='+91'>+91</option>
                                           
                                        </Input>
                                        <FormGroup className='Form-label-text1'>
                                            <Input
                                                onChange={phoneHandler}
                                                style={{ color: '#8c52ff' }}
                                                valid={mobileValid ? true : false}
                                                invalid={mobileInvalid ? true : false}
                                                placeholder="Phone number"
                                                value={mobile}
                                                maxLength={15}
                                                onPaste={(e) => preventCopyPaste(e)}
                                                onCopy={(e) => preventCopyPaste(e)}
                                                type="text" />
                                            <FormFeedback>Mobile Number is invalid!</FormFeedback>
                                        </FormGroup>
                                    </div>
                                    <div>
                                        <FormGroup className='Form-label-text1'>
                                            <div style={{ position: 'relative' }} onClick={()=>setUp(!up)}>
                                                <Input
                                                    className='reason'
                                                    style={{ color: '#8c52ff' }}
                                                    onChange={contactReasonHandler}
                                                    // valid={subjectValid ? true : false}
                                                    // invalid={subjectInvalid ? true : false}
                                                    placeholder="Subject"
                                                    value={subject}
                                                    type="text">
                                                    {/* <option value='--Select--'>--Select--</option> */}
                                                    {/* {helpType === 'Merchant' ? merchantReasonOptions : consumerReasonOptions} */}
                                                </Input>
                                                <div>
                                                    {down ? <img src={Down} style={{ position: 'absolute', right: 10, bottom: 5, zIndex: 100, cursor: 'pointer' }} onClick={downHandler} /> : <img src={Up} style={{ position: 'absolute', right: 18, bottom: 15, zIndex: 100, cursor: 'pointer' }} onClick={upHandler} />}   
                                                </div>

                                            </div>
                                            {up ?
                                                <Card style={{ borderWidth: 1, position: 'absolute' }}>

                                                    {helpType === 'Merchant' ? merchantReasonOptions : consumerReasonOptions}

                                                </Card> : null}
                                            <FormFeedback>Please select contact reason!</FormFeedback>
                                        </FormGroup>
                                    </div>
                                </div>

                                <FormGroup className='Form-label-text1' style={{ paddingTop: 28, }}>
                                    <Input
                                        onChange={messageHandler}
                                        valid={messageValid ? true : false}
                                        invalid={messageInvalid ? true : false}
                                        placeholder="Please type your message here"
                                        value={message}
                                        type="textarea"
                                        maxLength={200}
                                        onPaste={(e) => preventCopyPaste(e)}
                                        onCopy={(e) => preventCopyPaste(e)}
                                        style={{ height: '136px', color: '#8c52ff' }} />
                                    <FormFeedback>Message is invalid!</FormFeedback>
                                </FormGroup>

                                <FormGroup className="Form-group-button">
                                    <Button className='buttonstyl' onClick={submitHandler}>Submit</Button>
                                </FormGroup>

                                {/* <Button onClick={goToViolation} title='tammudu'/> */}

                            </Form>


                        </div>
                    </div>

                </div>
                {/* <div className='.HandS_Container_Home' style={{ flexDirection: 'row', display: 'flex', marginTop: 20 }} id={'faq'}>
                    <div style={{ flexDirection: 'column', display: 'flex', marginTop: 20, marginBottom: 15 }}>
                        <text className='faq'>Frequently Asked Questions</text>
                        <text className='faqSub'>Can’t find the answer you are looking for?</text>
                    </div>
                    <hr />
                    <div className='menu' onClick={userHandler} >

                        <Input
                            className='userOption'
                            style={{ color: '#8c52ff',cursor:'pointer' }}
                            onChange={userHandler}
                            placeholder="Subject"
                            value={selectOption}
                            type="text">
                        </Input>
                        {show &&control?
                            <Card style={{ borderWidth: 1, position: 'absolute', width: 185, cursor: 'pointer' }}>

                                {userOptions}

                            </Card> : null}
                    </div>
                </div>
                {user ? <CFAQ /> : <MFAQ />} */}


            </div>



        </div>
    );
}

export default RaiseComplain_pro_web;
