import React, { useContext, useEffect,useState } from 'react';
import './App.css';
import {useLocation, useRoutes } from 'react-router-dom';
import axios from 'axios';
import config from './config';
import { Store, globalState } from './New_Website_V2/GlobalState/globalState';
import LandingPageUpdated from './New_Website_V2/Screens/HomeStack/LandingPageUpdated';
import CheckoutPage from './New_Website_V2/Screens/CheckoutStack/CheckoutPage';
import ProfessionalPage from './New_Website_V2/Screens/HomeStack/ProfessionalPage';
import ProfessionalUnderCategory from './New_Website_V2/Screens/HomeStack/ProfessionalUnderCategory'
import ProfessionalUnderCategory_Mobile from './New_Website_V2/Screens/HomeStack/ProfessionalUnderCategory_Mobile'
import RatingsAndReviews from './New_Website_V2/Components/Custom/UI/RatingsAndReviews';
import SlotSelection from './New_Website_V2/Screens/CheckoutStack/SlotSelectionPage';
import MainNavigation from './New_Website_V2/Navigation/MainNavigation';
import FetchCurrentLocation from './New_Website_V2/Components/Custom/FetchCurrentLocation';
import customFetchData from './New_Website_V2/Components/Custom/customFetchData';
import { authAutoSignin,storeCountry,authLogOut,storeUtm, setAuthToken,authLoadItemsFromStorageToCart,loadServicesFromStorage, loadAddressFromStorage, loadDateAndTimeFromStorage } from './New_Website_V2/GlobalState/globalStateActions';
import {useNavigate} from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import HelpAndSupport from'./New_Website_V2/Screens/Help&Support/HelpAndSupport'
axios.defaults.baseURL=config.localhost_url;

// var collectchat = window.collectchat || {}; 
// collectchat.ready = function() {
//   console.log("chatbot ready loaded")
// }
global.latLng_Ip_Global={
  latitude:null,
  longitude:null
}

function App(){
  let navigate=useNavigate()
  const location = useLocation();
  const urlPath=location.pathname; 

  const [state,dispatch]=useContext(globalState)
  const [utm_campaign,setUtm_campaign]=useState(null)
  const [utm_source,setUtm_source]=useState(null)
  const [utm_medium,setUtm_medium]=useState(null)
  const [utm_content,setUtm_content]=useState(null)
  const [utm_term,setUtm_term]=useState(null)
  const [navContinueCheck,setNavContinueCheck]=useState(false)

  useEffect(()=>{
    // console.log("from app.js render check")

  },[])

const onLogOut=async()=>{
  // console.log("inside on logout")
  let logout=await authLogOut(dispatch)
  if(logout)
  {
      // navigate('/home',{});
      // navigate('/c/mybookings',{});
      // toast.success("Logout Successful", { position: toast.POSITION.BOTTOM_CENTER })
  }
  else
  {
      toast.success("Something went wrong. Please try again", { position: toast.POSITION.BOTTOM_CENTER })
  }
  // setNavContinueCheck(true)
}

const autoSignin=async()=>{
  ////To be uncommented
  let ipParsedRes;
  if(state.country.country==null){
    // console.log("ip if condition")
    let {fetchedData} =await customFetchData('GET',{},null,state,dispatch,"https://ipapi.co/json/?key=fd1b56f1a7e14bc7aa238e2d7e3b15d7166ea28c") // Custom hook to check network and fetch data
    ipParsedRes=fetchedData
  }else{
    // console.log("ip else condition")
    ipParsedRes={country:state.country.country,timezone:state.country.timezone,latitude:state.country.latitude,longitude:state.country.longitude}
  }

  ////To be commented
  // let ipParsedRes={country:"IN",timezone:"Asia/Kolkata",latitude:17.411,longitude:78.4487}

  // console.log("ipParsedRes",ipParsedRes)

  if(ipParsedRes.country)
  {
    authLoadItemsFromStorageToCart(dispatch)
    // loadAddressFromStorage(dispatch,urlPath)
    // loadServicesFromStorage(dispatch,urlPath)
    loadDateAndTimeFromStorage(dispatch)
      global.latLng_Ip_Global={
        latitude:ipParsedRes.latitude,
        longitude:ipParsedRes.longitude
      }
      // console.log("ipaddress.country",ipParsedRes.country);
      dispatch(storeCountry(ipParsedRes.country,ipParsedRes.timezone,ipParsedRes.latitude,ipParsedRes.longitude))

      let autoSignInCheck=await authAutoSignin(state,dispatch)
      console.log("state after autosignin",state)
      if(autoSignInCheck.autoSignIn)
      {
          // console.log("after auto signin",autoSignInCheck);
          if(autoSignInCheck.role==="consumer")
          {
              let utmBody={utm_campaign,utm_source,utm_medium,utm_content,utm_term}

              let {fetchedData}=await customFetchData('POST',utmBody,'/website/autosigninverify',state,dispatch,"",autoSignInCheck.token) // Custom hook to check network and fetch data
              // console.log("fetchedData",fetchedData)
              if(fetchedData.tokenValid)
              {
                  if(autoSignInCheck.id)
                  {
                      
                  }
                  setNavContinueCheck(true)
              }
              else
              {
                  console.log("authlogout 2")
                  onLogOut()
                  setNavContinueCheck(true)              
                }
          }
          else
          {
              console.log("authlogout 3")
              onLogOut()
              setNavContinueCheck(true)
          }
      }
      else
      {
              console.log("authlogout 1")
              onLogOut()
              setNavContinueCheck(true)
      }
  }
  else
  {
      console.log("unable to fetch ipinfo");
      toast.success("Something went wrong. Please try again", { position: toast.POSITION.BOTTOM_CENTER })
      setNavContinueCheck(true)
  }
}

  useEffect(()=>{
    // console.log("from app.js useefeect")
    autoSignin()
  },[])

    return ( 
      <MainNavigation navContinueCheck={navContinueCheck} />
    )
    // return <ConsumerEditProfile_Mobile/>
}

export default App;