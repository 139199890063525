import React from "react";
import '../../CSS/SlashExternalInformation/SlashInvestmentInformation.css';
import { Container } from "reactstrap";
import ShyamImage from '../../Assets/SlashTeamImages/ShyamCEO.png';
import SaiKiranImage from '../../Assets/SlashTeamImages/SaiKiran.png';
import PravallikaImage from '../../Assets/SlashTeamImages/Pravalika.png';

const SlashInvestInfo = () => {
    return (
        <Container className="mainPageContainer">
            <Container style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'left', padding:0 }}>
                <div>
                    <text className="mainPageHeaderFont">Investor Relation Center</text>
                </div>
                <div style={{paddingTop:32}}>
                    <text className="mainTextFont">Hi,</text><br /><br />
                    <text className="mainTextFont">Do you struggle with finding the right hair stylist just when you need it? I am <a target="_blank" href="https://www.linkedin.com/in/shyampanda">Shyam Panda</a>, founder of <a target="_blank" href="https://www.linkedin.com/company/goslashapp"> Slash </a>. I find it challenging to get a haircut quickly when I decide to get one. <b>Slash</b> is an innovative internet marketplace aimed at transforming the hair and beauty services industry to address this challenge. I am reaching out to introduce my project, <a target="_blank" href="https://www.linkedin.com/company/goslashapp"> Slash </a>, to you and share a promising investment opportunity. We're currently suggesting an investment of $100k or more to grow <a target="_blank" href="https://www.linkedin.com/company/goslashapp"> Slash </a>. This doc will give you most of the relevant information. For more information, you can <a class="mailto" href="mailto:spanda@goslash.com">email</a> me or <a target="_blank" href="https://www.linkedin.com/in/shyampanda/">DM me on Linkedin</a>.</text><br/><br/>
                    <text className="mainTextFont">If you want to have a call with me, <a target="_blank" href="https://calendly.com/shyampanda">please schedule it here</a>.</text><br /><br />

                    <text className="mainHeaderFont">A Brief Background</text><br /><br />
                        <text className="mainSubHeaderFont">1. What is Slash?</text><br /><br />
                            <text className="mainTextFont">Slash is an internet marketplace where consumers and service providers meet to execute hair and beauty services. Slash provides a Digital platform for locally owned/independent beauty salons to:</text><br /><br />
                            <ul>
                                <li><text className="mainTextFont">Promote and expand their business.</text></li>
                                <li><text className="mainTextFont">Maximize their time utilization and increase their earnings.</text></li>
                            </ul><br />

                        <text className="mainSubHeaderFont">2. What makes Slash stand out?</text><br /><br />
                            <text className="mainTextFont">Slash leverages AI and ML models to strategically create convenient offers in real time, specially customized to each customer’s data, and compels customers to:</text><br /><br />
                            <ul>
                                <li><text className="mainTextFont">Buy their first service booking</text></li>
                                <li><text className="mainTextFont">Purchase service bookings regularly to keep them active on Slash</text></li>
                            </ul><br />

                        <text className="mainSubHeaderFont">3. What has Slash accomplished as of March 2025?</text><br /><br />
                            <text className="mainTextFont">Operating within a small area of Hyderabad (India), Slash has:</text> <br /><br />
                            <ul>
                                <li><text className="mainTextFont">Completed more than 27,000 service bookings</text></li>
                                <li><text className="mainTextFont">Reached GMV run rate of $100,000 in ARR</text></li>
                                <li><text className="mainTextFont">Acquired more than 17,000 customers</text></li>
                                <li><text className="mainTextFont">Onboarded more than 1,000 service professionals</text></li>
                            </ul><br />

                        <text className="mainSubHeaderFont">4.  What will this round of funding achieve?</text><br /><br/>
                            <text className="mainTextFont">We plan to achieve the following:</text><br /><br />
                            <ul>
                                <li><text className="mainTextFont">Increase total acquired customers to more than 200,000</text></li>
                                <li><text className="mainTextFont">Expand to 10,000+ service professionals</text></li>
                                <li><text className="mainTextFont">Reach run rate of over 50,000 bookings per month</text></li>
                                <li><text className="mainTextFont">Cross GMV run rate of $1,000,000 in ARR</text></li>
                                <li><text className="mainTextFont">Achieve positive gross margin in Hyderabad and launch in other cities</text></li>
                            </ul><br />


                        <text className="mainSubHeaderFont">5. Will there be consistent progress updates?</text><br /><br />
                        <text className="mainTextFont">Yes, we do send out monthly updates to our Angel investors. You can read the <a target="_blank" href="/slash/Monthlyupdates">updates from March 2024 to February 2025 here</a>. If you want to receive future monthly updates, <a href="https://sibforms.com/serve/MUIFAMF7qxfK29TCmJIViysmhAC8NKS8ymDKjl8c9oOUmXGPzvtrl7NKwH5M8HYmP3CnxA_7kIOXS4oWBZg1Lk2xUsf7DnAbRiwbnfAz-IYQAoX9rX9eSOcoFkdpIMMe46A1tLAGgXVf9ShLPYgOmsdnWmHIMygmu4XGwYhowmL2lvRA5M5hb3okZx3hC4jQBcB9ppCL98UMXhLc" target="_blank"> sign up here.</a></text><br /><br />

                        <text className="mainSubHeaderFont">6. How does Slash u make revenue?</text><br /><br/>
                            <text className="mainTextFont">We charge a % of the service booking amount as platform fee from the service professionals. In future, we will experiment making money in additional ways:</text><br /><br />
                            <ul>
                                <li><text className="mainTextFont">Subscription fee from services professionals.</text></li>
                                <li><text className="mainTextFont">Advertisement fee for professionals to promote their services on our platform.</text></li>
                                <li><text className="mainTextFont">Mark up on service price.</text></li>
                                <li><text className="mainTextFont">Convenience fee from customers.</text></li>
                                <li><text className="mainTextFont">Subscription model with customers.</text></li>
                            </ul>
                            <text className="mainTextFont">Hair and Beauty services is our initial wedge. We will expand into other service categories in future.</text><br/><br />

                        <text className="mainSubHeaderFont">7. What are potential red flags  to be aware of?</text><br /><br />
                            <ul>
                                <li><text className="mainTextFont">I, <a target="_blank" href="https://www.linkedin.com/in/shyampanda">Shyam Panda</a>, am the solo founder of Slash.</text></li>
                                <li><text className="mainTextFont">I also founded <a target="_blank" href="https://www.linkedin.com/company/dilytics">DiLytics</a> in 2011 and continue to run it from the US while building Slash in Hyderabad (India).</text></li>
                                <li><text className="mainTextFont">I am passionate about Consumer Marketplaces. Yes, you read it right, I don’t have any experience in this space. The only experience I have in this space is building Slash with conviction and grit to be able to bring it to its current state of traction.</text></li>
                            </ul><br />

                        <text className="mainSubHeaderFont">8. Who is in your team?</text><br />
                            <text className="mainTextFont">We have a team of 25 people based in Hyderabad (India).</text><br /><br />
                            <ul>
                                <li style={{marginBottom:40}}>
                                    <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                                        <div className="teamSecFirstDiv">
                                            <text className="mainTextFont"><a target="_blank" href="https://www.linkedin.com/in/shyampanda">Shyam Panda</a>, <text style={{fontWeight:'bold'}}>Founder and CEO</text></text><br /><br />
                                        </div>
                                        <div>
                                            <img src={ShyamImage} className="teamImageStyle"/>
                                        </div>
                                    </div>

                                </li>
                                <li style={{marginBottom:40}}>
                                    <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                                        <div className="teamSecFirstDiv">
                                            <text className="mainTextFont"><a target="_blank" href="https://www.linkedin.com/in/jetti-sai-kiran-aa0832b6/">Sai Kiran Jetti</a>, <text style={{fontWeight:'bold'}}>Product Development Lead, Ex-Amazon</text></text><br />
                                                <ul>
                                                    <li><text className="mainTextFont">6 Software developers</text></li>
                                                    <li><text className="mainTextFont">1 Product Designer</text></li>
                                                </ul>
                                               
                                        </div>
                                        <div>
                                            <img src={SaiKiranImage} className="teamImageStyle"/>
                                        </div>
                                    </div>

                                </li>
                                <li style={{marginBottom:40}}>
                                    <div style={{display:'flex', flexDirection:'row', width:'100%'}}>
                                        <div className="teamSecFirstDiv">
                                            <text className="mainTextFont"><a target="_blank" href="https://www.linkedin.com/in/pravallika-srigiri-5a050b18b/">Pravallika Srigiri</a>, <text style={{fontWeight:'bold'}}>Marketing Manager, Ex-MyGate</text></text><br />
                                            <ul>
                                                <li><text className="mainTextFont">1 UX Designer</text></li>
                                                <li><text className="mainTextFont">1 Graphic Designer</text></li>
                                                <li><text className="mainTextFont">14 Customer Service Reps</text></li>
                                            </ul>
                                        </div>
                                        <div>
                                            <img src={PravallikaImage} className="teamImageStyle"/>
                                        </div>
                                    </div>

                                </li>
                            </ul>




                    <text className="mainHeaderFont">Financial</text><br /><br />
                        <text className="mainSubHeaderFont">What are the terms on the round?</text><br /><br />
                        <text className="mainTextFont">We are raising between $2M and $3M on a $10M premoney via preferred shares.</text><br /><br />
                        <text className="mainTextFont">This is the second round of fundraising, after <a target="_blank" href="https://www.linkedin.com/feed/update/urn:li:activity:7199619712916283393/">I raised $250k from 10 friends ($25k each) via 20% SAFE notes, in March 2024</a>. This angel round was fully subscribed within 2 weeks from start to finish.</text><br /><br />

                        <text className="mainSubHeaderFont">How much can I invest?</text><br /><br />
                            <text className="mainTextFont">Ideally between $100k to $500k. We are looking for investors with tremendous experience in the Consumer space, preferably in Marketplaces. We would like to keep the $500k limit per investor, so that we can onboard between 8 to 11 great fit investors to help us on our path forward. We may take more than $500k from one investor in an exceptionally great fit scenario.</text><br /><br />

                        <text className="mainSubHeaderFont">How did you come up with the $10M premoney valuation?</text><br /><br />
                            <text className="mainTextFont">Kinda arbitrarily, like all early stage funding rounds. I believe this valuation is a midpoint between a reasonable amount of dilution and opportunity to raise the next round at a 5x after reaching our goals from this round.</text> <br /><br />

                        <text className="mainSubHeaderFont">Why do you need to raise money when you can use money from your other company <a target="_blank" href="https://www.linkedin.com/company/dilytics">DiLytics</a>?</text><br /><br />
                            <text className="mainTextFont">I started DiLytics in 2011 with the ambition to launch the initial development of <a target="_blank" href="https://www.linkedin.com/company/goslashapp">Slash</a> using money from DiLytics. I have achieved that initial objective from DiLytics. Now, it’s time for Slash to grow faster with additional funding for its future growth. Most importantly, we are looking forward to getting advice and guidance from great fit investors/mentors.</text><br /><br />

                        <text className="mainSubHeaderFont">Will you raise more money after this round?</text><br /><br />
                            <text className="mainTextFont">Our goal is to achieve a GMV of $1M in ARR before raising $10-15M Series A next year. My target is to achieve a positive gross margin in all our transactions, using the Series A funding, so that any future fundraising is a choice, rather than a necessity.</text><br /><br />

                        <text className="mainSubHeaderFont">OK cool, I am sold. What next?</text><br /><br />
                            <text className="mainTextFont">You can <a target="_blank" href="https://forms.gle/NfVx27Dd213zNVpV9">fill out this google form</a>. Don’t worry, this is not a legally binding form, it’s just an expression of interest. You 100% can back out later if you'd like but this is a way for me to track interest and prioritize. Alternatively, you can <a class="mailto" href="mailto:spanda@goslash.com">email</a> me or <a target="_blank" href="https://www.linkedin.com/in/shyampanda/">DM me on Linkedin</a>.  </text><br /><br />

                    <ul>
                        <li><text className="mainTextFont">Want to have a call with me? <a target="_blank" href="https://calendly.com/shyampanda">Please schedule it here</a>.</text></li>
                        {/* <li><text className="mainTextFont">You are delusional, I am out 😀</text></li> */}
                    </ul>

                </div>
            </Container>
        </Container>
    )
}

export default SlashInvestInfo;