import React from "react";

const SlashPDFEmbed = () => {
  return (
    <div style={{width:'100%', minHeight:'700px', position: "relative", overflow: 'scroll'}}>
      {/* <iframe src={SlashDocumentsPDF} height={window.innerHeight} width={window.innerWidth}></iframe> */}
      <iframe src={"https://docs.google.com/document/d/1j4nIFPXSsAp6gUG4S0VjG_7YTmPCLcKy3mtf-dHgxoc/preview"} height={window.innerHeight} width={window.innerWidth}></iframe>
    </div>
  );
};

export default SlashPDFEmbed;