
import React,{Component, useState,useLayoutEffect,useContext,useEffect} from "react";
import { resolvePath } from "react-router-dom";
import {useRef} from 'react';
import deleteIcon from './New_Website_V2/Assets/iconsDelete24Px.png'
import RatingComponent from './New_Website_V2/Components/Custom/UI/RatingComponent';

import SubmitButtonSmall from "./New_Website_V2/Components/Custom/UI/submitButtonSmall";
import CategoriesButton from "./New_Website_V2/Components/CategoriesButton";
import customFetchData from './New_Website_V2/Components/Custom/customFetchData';
import {globalState} from './New_Website_V2/GlobalState/globalState';
import {Input,Modal,ModalBody,ModalHeader,ModalFooter, Button} from 'reactstrap'
import cross from './New_Website_V2/Assets/crossIcon.png'
import { useNavigate, NavLink } from 'react-router-dom';
import { translate } from "react-range/lib/utils";
import CloseIcon from './New_Website_V2/Assets/icons-closeModal.png'
import ServiceDetails from './New_Website_V2/Components/Custom/UI/ServiceDetails'
import { ClipLoader } from 'react-spinners';
import Close_Cross from "./New_Website_V2/Components/Custom/UI/Close_Cross";
import moment from "moment";
import FetchCurrentLocation from "./New_Website_V2/Components/Custom/FetchCurrentLocation";
import './New_Website_V2/CSS/FAQ.css'

function WishList_mobile(){

  const navigate = useNavigate();

   let [state,dispatch]=useContext(globalState)    
   const [error,setErrorMsg] = useState('')
   const [loading,setLoading]=useState(false)
   const [selected, setSelected] = useState(99980) 
   const [selected1, setSelected1] = useState(99980) 
   const [categoryButtons,setcategoryButtons] = useState([])
   const [categoryButtonsServices,setcategoryButtonsServices] = useState([])
   const [professionalData ,setProfessionalData] = useState([])
   const [professionalDataCopy ,setProfessionalDataCopy] = useState([])
   const [serivcesData ,setServicesData] = useState([]) 
   const [serivcesDataCopy ,setServicesDataCopy] = useState([])
   const [showModal,setShowModal]= useState({Modal:false,wshLstPrid:null})
   const [showModal1,setShowModal1]= useState({Modal1:false,wshLstPrid1:null})
   const [ProOrSer,setProOrSer] = useState(['Services'])
   const [serviceDetailsModal,setServiceDetailsModal]=useState(false)
    const [serviceDetails,setServiceDetails]=useState()
    const [serviceModalData,setServiceModalData]=useState([])
    const [allDupCategories,setAllDupCategories]=useState([])
    const [ProData,setProData] = useState([]) 
    const [DataSer, setDataser ]= useState([]) 
  const [city, setCity] = useState('')
  const [latitude, setLatitude] = useState()
  const [longitude, setLongitude] = useState()
  const [stateOrProvince, setStateOrProvince] = useState('')
    const DeepClone = require("rfdc/default")






   useEffect(()=>{

    setLoading(true)
    fetchWishListData()
    fetchWishListDataServices()
    fetchCategoriesServices()
    fetchCurrentLocationHandler()
  },[])

  const fetchCurrentLocationHandler=async()=>{
    if(state.address.latitude === null && state.address.longitude === null){
      let addressDetails= await FetchCurrentLocation(state,dispatch)
      console.log('address details',addressDetails)
      let {city,country,curr_location_latitude,curr_location_longitude,stateOrProvince}=addressDetails
      setLatitude(curr_location_latitude)
      setLongitude(curr_location_longitude)
      setCity(city)
      setStateOrProvince(stateOrProvince)
    }else{
      setLatitude(state.address.latitude)
      setLongitude(state.address.longitude)
      setCity(state.address.city)
      setStateOrProvince(state.address.stateOrProvince)
    }
  }
  const PorShandler = (event) =>{

    setProOrSer(event.target.value)
   
}


const serviceDetailsModalClose =()=>{
  setServiceDetailsModal(false)
  setServiceDetails()
}

const fetchWishListDataServices = async ()=>{
  let  method="POST",body={wishLstType:'service',cPrid:state.user.id},url="/website/fetch/wishList/sv2"
  let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)
  if(fetch){  
    if(fetchedData.wishListData.length){
       setServicesData(fetchedData.wishListData)
       setServicesDataCopy(fetchedData.wishListData) 
       setErrorMsg('')
       setLoading(false)
    }else{
      setErrorMsg('No wishlisted services found!')
    }
  }else{
    console.log('error')
  }
}




 const fetchCategoriesServices = async () => {
  let  method="POST",body={cPrid:state.user.id},url="/website/city/avail/categories/sv2"
  let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)   
  if(fetch){  
   let cats = fetchedData.catResults
    if(fetchedData.catResults.length!==0){
        cats.unshift({QF_SERVICE_CATEGORY_PRID:99980,category_name:'All'})
        setcategoryButtonsServices(cats)
    }else{
      setcategoryButtonsServices([])

    }
  }else{
    setcategoryButtonsServices([])
    console.log('error')
  }
}




const fetchWishListData = async () => {

  let  method="POST",body={wishLstType:'professional',cPrid:state.user.id},url="/website/fetch/wishList/sv2"
  let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)
  console.log('fectch dataPRO',fetchedData)   
  if(fetch){  

    let cats = fetchedData.wishListData

    if(fetchedData.wishListData.length){
      
        setAllDupCategories(cats)
        
        // setProfessionalDataCopy(fetchedData.wishListData)
        const uniquePro= cats.filter((obj, index) => cats.findIndex((item) => item.QF_MERCHANT_PERSONNEL_PRID === obj.QF_MERCHANT_PERSONNEL_PRID) === index)
        
        cats.unshift({QF_SERVICE_CATEGORY_PRID:99980,category_name:'All'})
        
        const uniqueProCatshow = cats.filter((obj, index) => cats.findIndex((item) => item.category_name === obj.category_name) === index)
         
        setProfessionalData(uniquePro)
        setErrorMsg('')
        setLoading(false)
        setcategoryButtons(uniqueProCatshow)
    }else{
      setProfessionalData([])
      setLoading(false)
      setcategoryButtons([])

      setErrorMsg('No wishlisted services found!')
    }
  }else{
    console.log('error')
  }
}










async function removeFrmWshLst(id,MPRID){

    let  method="POST",body={id:id},url="/website/wishList/delete/sv2"
    let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)  
    if(fetch){  

      let UpdateprofessionalData = professionalData.filter((each) => {return each.QF_WISHLIST_PRID != id})
       
      if(!UpdateprofessionalData.length){

        setProfessionalDataCopy([])
        setcategoryButtons([])
        setAllDupCategories([])
        setProfessionalData([])
       }
       else{
       let UpdateAllDupCategories = allDupCategories.filter((each) => {return each.QF_WISHLIST_PRID != id})
      //  let UpdatecategoryButtons = categoryButtons.filter((each) => {return each.QF_WISHLIST_PRID != id})
       const deletedProDupcategoriesRemoved  = UpdateAllDupCategories.filter((obj, index) => UpdateAllDupCategories.findIndex((item) => item.category_name === obj.category_name) === index)
      
       setAllDupCategories(UpdateAllDupCategories)
       setcategoryButtons(deletedProDupcategoriesRemoved)
       setProfessionalDataCopy(UpdateAllDupCategories)
       setProfessionalData(UpdateprofessionalData)
       setSelected(99980)


       }

       setShowModal({...showModal,Modal:false})
       setLoading(false)
    }
    else{
      console.log('error')
    }
 }



 async function removeFrmWshLstServices(id){


  // return console.log(id);
    let  method="POST",body={id:id},url="/website/wishList/delete/sv2"
    let {fetch,fetchedData}= await customFetchData(method,body,url,state,dispatch)  
    // console.log(fetch,fetchedData)
    if(fetch){  
       let afterRemoveItems = serivcesData.filter((each) => {return each.QF_WISHLIST_PRID != showModal1.wshLstPrid1})

        if(!afterRemoveItems.length){
          setServicesDataCopy([])
         }
        
          // setServicesDataCopy(removedpro)
      //  setdupRemovedData(uniqueProCatshow)
          setServicesData(afterRemoveItems)
          setServicesDataCopy(afterRemoveItems)
      //  setCopyCards(afterRemoveItems)
         setShowModal1({...showModal,Modal:false})
         setLoading(false)
    }
    else{
      console.log('error')
    }
 }

 const handleButtonClick = (id,cat) => {

  if(id!=99980){
      setSelected(id) 
      let catSelectedOnly = allDupCategories.filter((each) => {
          return each.category_name == cat} )
        const uniquePro= catSelectedOnly.filter((obj, index) => catSelectedOnly.findIndex((item) => item.QF_MERCHANT_PERSONNEL_PRID === obj.QF_MERCHANT_PERSONNEL_PRID) === index)    
          if(catSelectedOnly.length){
           setProfessionalDataCopy(uniquePro)  
          }else{
            setProfessionalDataCopy([])
          }

  }else{
    setErrorMsg('')
    setSelected(id)
  }

 }

   const handleButtonClickServices = (id) => {

    if(id!=99980){  
        setSelected1(id)
        let catSelectedOnly = serivcesData.filter((each) => {
            return each.QF_SERVICE_CATEGORY_PRID == id} )
            if(catSelectedOnly.length){
              setServicesDataCopy(catSelectedOnly)
              setErrorMsg('')
            }else{
              setErrorMsg('No wishlisted services found!')
            }

    }else{
      setErrorMsg('')
      setSelected1(id)
      // setCards(copyCards)
    }

   }

   const serviceExplorePress = (data) =>{


    let offers=[...serivcesData]
    let filteredPro =[]
    filteredPro = offers.filter((each)=>{

         return each.QF_MERCHANT_SERVICE_PRID == data.QF_MERCHANT_SERVICE_PRID
    })

    // console.log(data,state.services)
    // let offers=DeepClone(state.services);
    // let filteredPro=[];
    //  offers.map(item=>{
    //   item.map(item1=>{
    //     if(item1.QF_MERCHANT_SERVICE_PRID == data.QF_MERCHANT_SERVICE_PRID){
    //       return filteredPro.push(item1)
    //     }
    //   })
    // })
    // if(filteredPro.length!==0){

      // setProData(filteredPro[0])
      // setDataser(filteredPro[0])
      navigate({pathname:`/servicedetails/${data.SERVICE_NAME.replaceAll(" ","","g")}` ,search:`proId=${data.QF_MERCHANT_PERSONNEL_PRID}&serviceId=${data.QF_MERCHANT_SERVICE_PRID}`}
      ,{state:{proData:filteredPro[0],serviceDetails:filteredPro[0],wishlisted:true,
        categories:DeepClone(state.categories),subCategories:DeepClone(state.subcategories),
        addressDetails:
     {
        totalAddress: state.address.totalAddress,
        addressLine1: state.address.addressLine1,
        country: state.address.country,
        city: state.address.city,
        stateOrProvince: state.address.stateOrProvince,
        zipcode: state.address.zipcode,
        district: state.address.district,
        latitude: state.address.latitude,
        longitude: state.address.longitude,
        currentAddress: state.address.currentAddress,
        searchAddress: state.address.searchAddress,
        currentLocationDenied: state.address.currentLocationDenied
     },allServices:DeepClone(state.services)}}
     )
    // }
  
  }   

  const onPressProExplore = (item) =>{
        let offers = state.services
        // let filteredServices,proData;
        // filteredServices = offers.filter(outerItem => {
        //     return outerItem[0].QF_MERCHANT_PERSONNEL_PRID == item.QF_MERCHANT_PERSONNEL_PRID
        // })
        // if (filteredServices.length !== 0) {
            navigate({pathname:`/allservices/${item.MERCHANT_NAME.replaceAll(" ","","g")}`,search:`?proId=${item.QF_MERCHANT_PERSONNEL_PRID}`}
          //   , { state: { proData: filteredServices[0][0],offers: filteredServices[0],proChecked:true,fromWihslist:true,
          //     categories:DeepClone(state.categories),subCategories:DeepClone(state.subcategories),
          //     addressDetails:
          //  {
          //     totalAddress: state.address.totalAddress,
          //     addressLine1: state.address.addressLine1,
          //     country: state.address.country,
          //     city: state.address.city,
          //     stateOrProvince: state.address.stateOrProvince,
          //     zipcode: state.address.zipcode,
          //     district: state.address.district,
          //     latitude: state.address.latitude,
          //     longitude: state.address.longitude,
          //     currentAddress: state.address.currentAddress,
          //     searchAddress: state.address.searchAddress,
          //     currentLocationDenied: state.address.currentLocationDenied
          //  },allServices:DeepClone(state.services)} }
           )
        // }
   }


   



 let data=[]

 if(ProOrSer == 'Services'){

  if(serivcesData.length !== 0  && selected1 == 99980 ){ 


    data =  serivcesData.map((each)=>{

      return(

        <div onClick={()=>serviceExplorePress(each)} style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative'}}>
        <div> 
           <img style={{height:'35vw',width:'40vw',borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
           <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:10,borderRadius:8}}>
              <img onClick={(e)=>{
                e.stopPropagation()
                return setShowModal1({...showModal1,Modal:true,wshLstPrid1:each.QF_WISHLIST_PRID})}} style={{margin:4}} src={deleteIcon} />
          </div>
          { each.MERCHANT_SERVICE_RATING ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
                      <RatingComponent rating={each.MERCHANT_SERVICE_RATING} webUsage={false} style={{fontsize:10}} width={45} height={26} foNsize={10}  />
                    </div> : null}
       </div>
          
          <div style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-12,paddingTop:16, width:'40vw',position:'relative',overflow:'hidden'}}>
          
            {/* <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:119,marginBottom:20,flexDirection:'column',justifyContent:'space-between',alignItems:'center',display:'flex',paddingTop:20}}>
              <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80 ,width:'80%'}}> 
                 <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:'3vw',letterSpacing:0,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0}}>{each.SERVICE_NAME}</p>
                 <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#e0e0e0",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:2,lineClamp:2,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.SERVICE_DETAIL_DESC ? each.SERVICE_DETAIL_DESC :null}</p>
              </div>
              <div style={{ width:'80%',marginTop:-25}}>
                   <SubmitButtonSmall onPress={()=>serviceExplorePress(each)} Radius={5}  width={'100%'} marginTop={0} buttonTitle={'Explore service'} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
              </div>
            </div> */}

             <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
              <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80 ,width:'80%'}}> 
                 <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.SERVICE_NAME}</p>
                     <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:14,letterSpacing:0,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0}}><span style={{fontSize:10,fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',color:'#e0e0e0',textDecorationLine:'line-through'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.service_list_price}</span><span style={{fontSize:10,fontWeight:'600',fontFamily:'Inter',marginLeft:2.5,fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.SERVICE_OFFER_PRICE}</span> <span style={{fontSize:10,color:'#f29837',fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal',fontWeight:'normal'}}>({each.service_discount}%)</span></p>
                     <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,width:'100%',marginBottom:0,color:'#e0e0e0',lineHeight:'normal',letterSpacing:'normal',fontStretch:'normal',fontStyle:'normal',fontWeight:'normal'}}>{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") + " hr"}{' '}{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + " mins"}</p>

                 {/* <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#e0e0e0",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:2,lineClamp:2,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.SERVICE_DETAIL_DESC ? each.SERVICE_DETAIL_DESC :null}</p> */}
              </div>
              <div style={{ width:'80%',marginTop:-25}}>
                   <SubmitButtonSmall onPress={()=>serviceExplorePress(each)} Radius={5} topMar={true}  width={'100%'} marginTop={0} buttonTitle={'Explore service'} height={30} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
              </div>
            </div>
         </div>



{/*          

      <Modal isOpen={showModal1.Modal} style={{backgroundColor:'#ffffff',width:'80vw',position:'absolute',top:'50%',left:'50%',transform:'translate(-50%,-50%)'}}>
      <ModalBody>
          <div className="modalContainerStyle">
              <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', marginBottom:15}}>
                  <text className="modalHeadingText">Remove from cart ?</text>
                  <Close_Cross onClick={()=>setShowModal1({...showModal1,Modal:false})} />
              </div>
              <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between'}}>
                  <div onClick={()=>setShowModal1({...showModal1,Modal:false})}  className="MoveButtonStyle">
                      <text className="ButtonMoveText">No</text>
                  </div>

                  <div onClick={()=>removeFrmWshLstServices(showModal1.wshLstPrid1)} className="RemoveButtonStyle">
                      <text className="ButtonRemoveText">Remove</text>
                  </div>
              </div>
          </div>
      </ModalBody>
    </Modal> */}
      </div> 
      )
    })
   
  }else{

    if(serivcesDataCopy.length != 0){

    data =  serivcesDataCopy.map((each)=>{

      return(

        <div onClick={()=>serviceExplorePress(each)} style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative'}}>
        <div> 
           <img style={{height:'35vw',width:'40vw',borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
           <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:10,borderRadius:8}}>
           <img onClick={(e)=>{
                e.stopPropagation()
                return setShowModal1({...showModal1,Modal:true,wshLstPrid1:each.QF_WISHLIST_PRID})}} style={{margin:4}} src={deleteIcon} />
          </div>
          { each.MERCHANT_SERVICE_RATING ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
                      <RatingComponent rating={each.MERCHANT_SERVICE_RATING} webUsage={false} style={{fontsize:10}} width={45} height={26} foNsize={10}  />
                    </div> : null}
       </div>
          
          <div style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-12,paddingTop:16, width:'40vw',position:'relative',overflow:'hidden'}}>
          
           

             <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
              <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80 ,width:'80%'}}> 
                 <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.SERVICE_NAME}</p>
                     <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:14,letterSpacing:0,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0}}><span style={{fontSize:10,fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',color:'#e0e0e0',textDecorationLine:'line-through'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.service_list_price}</span><span style={{fontSize:10,fontWeight:'600',fontFamily:'Inter',marginLeft:2.5,fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal'}}>{each.SERVICE_CURRENCY === 'Rupees' ? ('\u20B9') : '$'}{each.SERVICE_OFFER_PRICE}</span> <span style={{fontSize:10,color:'#f29837',fontFamily:'Inter',fontStretch:'normal',fontStyle:'normal',lineHeight:"normal",letterSpacing:'normal',fontWeight:'normal'}}>({each.service_discount}%)</span></p>
                     <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,width:'100%',marginBottom:0,color:'#e0e0e0',lineHeight:'normal',letterSpacing:'normal',fontStretch:'normal',fontStyle:'normal',fontWeight:'normal'}}>{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("H") + " hr"}{' '}{moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") == 0 ? null : moment.utc(moment.duration(each.SERVICE_DURATION_IN_MINS, "minutes").asMilliseconds()).format("mm") + " mins"}</p>

                 {/* <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#e0e0e0",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:2,lineClamp:2,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.SERVICE_DETAIL_DESC ? each.SERVICE_DETAIL_DESC :null}</p> */}
              </div>
              <div style={{ width:'80%',marginTop:-25}}>
                   <SubmitButtonSmall onPress={()=>serviceExplorePress(each)} Radius={5} topMar={true}  width={'100%'} marginTop={0} buttonTitle={'Explore service'} height={30} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
              </div>
            </div>
         </div>
      </div> 
      )
    })
  }else{

       data = <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginBottom:310}}>
       <text style={{textAlign:'center',flexGrow:0,padding:0,fontFamily:'Inter',fontSize:14,fontWeight:500,fontStretch:'normal',fontStyle:'normal',color:'#000',lineHeight:'normal',letterSpacing:'normal'}}>No wishlisted services found!</text>
       </div>

  }


  }


}
else{

if(professionalData.length !== 0  && selected == 99980 ){  

  data =  professionalData.map((each)=>{

    return(

      <div onClick={()=>onPressProExplore(each)} style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative'}}>
        <div> 
           <img style={{height:'35vw',width:'40vw',borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
           <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:10,borderRadius:8,}}>
           <img onClick={(e)=>{e.stopPropagation()
                               return setShowModal({...showModal,Modal:true,wshLstPrid:each.QF_WISHLIST_PRID})}} style={{margin:4}} src={deleteIcon} />
        </div>
        { each.MERCHANT_PERSONNEL_RATING  ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
                    <RatingComponent rating={each.MERCHANT_PERSONNEL_RATING}  webUsage={false} style={{fontsize:10}} width={45} height={26} foNsize={10} />
                  </div> : null}
       </div>
       {/* <div style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-30,paddingTop:24,paddingBottom:'1%', width:'40vw'}}>
       
          <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:119,marginBottom:20,flexDirection:'column',justifyContent:'space-between',alignItems:'center',display:'flex',paddingTop:20}}>
            <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80,width:'80%'}}> 
            <p style={{ fontFamily: "Inter",fontStyle: "normal", letterSpacing: 0,color: "#FFFFFF",fontSize:'3vw',letterSpacing:0,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0}}>{each.MERCHANT_NAME}</p>
            <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#e0e0e0",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:2,lineClamp:2,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.desc ? each.desc :null}</p>
            </div>
            <div style={{ width:'80%', marginTop:-25}}>
                 <SubmitButtonSmall onPress={()=>onPressProExplore(each)} Radius={5}  width={'100%'} size={true} buttonTitle={'Explore services'} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
            </div>
          </div>  
       </div> */}

        <div style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-12,paddingTop:16,zIndex:10,width:'40vw',position:'relative',overflow:'hidden'}}>
           
           <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
           <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80,width:'80%'}}> 
           <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.MERCHANT_NAME}</p>
           <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:1,lineClamp:1,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.MERCHANT_PERSONNEL_ADDRESS_LINE2 ? each.MERCHANT_PERSONNEL_ADDRESS_LINE2 :null}</p>
           </div>
             <div style={{ width:'80%',marginTop:5}}>
                  <SubmitButtonSmall onPress={()=>onPressProExplore(each)} topMar={true}  Radius={5} height={30}  width={'100%'} marginTop={0} buttonTitle={'Explore services'} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
             </div>
           </div>
        </div>
 
       

      </div> 
    )
  })
  
 }else{

  if(professionalDataCopy.length!==0){

    data =  professionalDataCopy.map((each)=>{

      return(

        <div onClick={()=>onPressProExplore(each)}  style={{borderRadius: 4.2,shadowColor: "rgba(0, 0, 0, 0.04)", shadowOffset: {width: 0,height: 0 },shadowRadius: 31,shadowOpacity: 0,float: 'left',marginBottom:42,position:'relative',zIndex:10}}>
        <div> 
           <img style={{height:'35vw',width:'40vw',borderTopLeftRadius:15,borderTopRightRadius:15}} src={each.image_link}></img>
           <div style={{backgroundColor:'#ffffff',height:26,width:26,position:'absolute',top:16,right:'8%',zIndex:1000,borderRadius:8,}}>
            <img onClick={(e)=>{e.stopPropagation()
                               return setShowModal({...showModal,Modal:true,wshLstPrid:each.QF_WISHLIST_PRID})}} style={{margin:4}} src={deleteIcon} />
        </div>
        { each.MERCHANT_PERSONNEL_RATING  ? <div style={{backgroundColor:'#ffffff',position:'absolute',top:16,left:'8%',zIndex:10,borderRadius:8}}>
                    <RatingComponent rating={each.MERCHANT_PERSONNEL_RATING}  webUsage={false} style={{fontsize:10}} width={45} height={26} foNsize={10} />
                  </div> : null}
       </div>
     

        <div onClick={()=>onPressProExplore(each)} style={{borderRadius: 15,backgroundColor: "#8c52ff",marginTop:-12,paddingTop:16,zIndex:10,width:'40vw',position:'relative',overflow:'hidden'}}>
           
           <div style={{height : each.WISHLIST_TYPE == 'professional' ? 90: null,width:'100%',height:103,flexDirection:'column',alignItems:'center',display:'flex'}}>
           <div style={{height: each.WISHLIST_TYPE == 'professional' ? 50: 80,width:'80%'}}> 
           <p style={{ fontFamily: "Inter",lineHeight:'normal',fontStyle: "normal", letterSpacing: "normal",color: "#fff",fontSize:14,textOverflow:'ellipsis',overflow:'hidden',whiteSpace:'nowrap',width:'100%',marginBottom:0,fontWeight:'600'}}>{each.MERCHANT_NAME}</p>
           <p style={{ fontFamily: "Inter",fontStyle: "normal",letterSpacing: 0,color: "#FFFFFF",fontSize:10,letterSpacing:0,marginBottom:0,display:'-webkit-box',WebkitLineClamp:1,lineClamp:1,WebkitBoxOrient:'vertical',textOverflow:'ellipsis',overflow:'hidden'}}>{each.MERCHANT_PERSONNEL_ADDRESS_LINE2 ? each.MERCHANT_PERSONNEL_ADDRESS_LINE2 :null}</p>
           </div>
             <div style={{ width:'80%',marginTop:5}}>
                  <SubmitButtonSmall onPress={()=>onPressProExplore(each)} topMar={true}  Radius={5} height={30}  width={'100%'} marginTop={0} buttonTitle={'Explore services'} buttonColor={'#FFFFFF'} buttonTextColor={'#8c52ff'} />
             </div>
           </div>
        </div>
 
       

      </div> )
  
        
  
  
        // </div> 
      
    })
}else{

  data = <div style={{width:'100%',display:'flex',flexDirection:'row',justifyContent:'center',alignItems:'center',marginBottom:310}}>
  <text style={{textAlign:'center',flexGrow:0,padding:0,fontFamily:'Inter',fontSize:14,fontWeight:500,fontStretch:'normal',fontStyle:'normal',color:'#000',lineHeight:'normal',letterSpacing:'normal'}}>No wishlisted professionals found!</text></div>
}


  
//  }
// }else{

//       data =  'services'

// }

 }
}
let serviceDetailsDisplayModal=(
  <Modal isOpen={serviceDetailsModal} size={'md'}  toggle={()=>serviceDetailsModalClose()} centered>
    <div style={{padding:16}}>
      <div style={{justifyContent:'flex-end',display:'flex',flexDirection:'row'}}>
        <div className='crossIcon_ViewPro' style={{cursor:'pointer'}}>
          <img src={CloseIcon} style={{width:18,height:18}} onClick={()=>serviceDetailsModalClose()}/>
        </div>
      </div>
      <div style={{marginTop:20}}>
        <ServiceDetails proData={ProData} serviceDetails={DataSer}/>
      </div>
    </div>
  </Modal>
 )

 let serModal=(
  <Modal isOpen={showModal1.Modal} size={"md"} centered>
      <div style={{padding:20,backgroundColor:'#fff', borderRadius:10}}>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
              <text className='are_you_wantText'>Remove from cart ?</text>
              <div onClick={()=>setShowModal1({...showModal1,Modal:false})} style={{cursor:'pointer',cboxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',width:22,height:22,borderRadius:20,backgroundColor: '#fff',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
                  <img src={CloseIcon} style={{width:16,height:16}}/>
              </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
              <div className='yes_Logout_Btn' onClick={()=>setShowModal1({...showModal1,Modal:false})}>
                  <text className='yes_Text_Logout'>No</text>
              </div>
              <div className='cancel_Logout_Btn' onClick={()=>removeFrmWshLstServices(showModal1.wshLstPrid1)} style={{marginLeft:20}}>
                  <text className='cancel_Text_Logout'>Remove</text>
              </div>
          </div>
      </div>
  </Modal>
)




 let proModal=(
  <Modal isOpen={showModal.Modal} size={"md"} centered>
      <div style={{padding:20,backgroundColor:'#fff', borderRadius:10}}>
          <div style={{display:'flex',justifyContent:'space-between',flexDirection:'row'}}>
              <text className='are_you_wantText'>Remove from cart ?</text>
              <div onClick={()=>setShowModal({...showModal,Modal:false})} style={{cursor:'pointer',cboxShadow: '0 2px 4px 0 rgba(103, 52, 204, 0.1)',width:22,height:22,borderRadius:20,backgroundColor: '#fff',display:'flex',justifyContent:'center',flexDirection:'row',alignItems:'center'}}>
                  <img src={CloseIcon} style={{width:16,height:16}}/>
              </div>
          </div>
          <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:20}}>
              <div className='yes_Logout_Btn' onClick={()=>setShowModal({...showModal,Modal:false})}>
                  <text className='yes_Text_Logout'>No</text>
              </div>
              <div className='cancel_Logout_Btn' onClick={()=>removeFrmWshLst(showModal.wshLstPrid)} style={{marginLeft:20}}>
                  <text className='cancel_Text_Logout'>Remove</text>
              </div>
          </div>
      </div>
  </Modal>
)





const preventCopyPaste = (e) => {
  e.preventDefault()
  // toast.info("Copying and pasting is not allowed!", {position: toast.POSITION.BOTTOM_CENTER})
}

  

    return(

        <div style={{  marginLeft: '7%', marginRight: '7%',paddingTop:100  }}>
         <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>    
             <text style={{fontSize:24,fontWeight:'bold',fontFamily:'Inter',}}>Wishlist</text>
             <Input
                                                style={{ color: '#8c52ff',fontSize:14,fontWeight:'normal',fontFamily:'Inter',width:140,border:0 }}
                                                onChange={PorShandler}
                                                placeholder="Subject"
                                                value={ProOrSer}
                                                maxLength={20}
                                                type="select">
                                                <option style={{backgroundColor:'black'}} value='Services'>Services</option>
                                                <option value='Professionals'>Professionals</option>

                                       </Input>
         </div>
         <h2 style={{ textAlign: 'center', borderBottom: '1px solid #b8b8b8', lineHeight: '0.1em', opacity: 0.6, height: 7, flexGrow: 0,marginBottom:43 }} />

        
      
           <div  style={{display:'flex',overflowX:'scroll',overflowY:'hidden',scrollSnapType:'x',width:'100%',flexDirection:'row'}} className="proDIV"> 
           { categoryButtons.length  && ProOrSer == 'Professionals'  ?
              categoryButtons.map((each,index)=>{

           return(
                  <div className="proDIV" style={{marginBottom:20}}>
                   <CategoriesButton  handle={()=>handleButtonClick(each.QF_SERVICE_CATEGORY_PRID,each.category_name)} buttonColor={each.QF_SERVICE_CATEGORY_PRID === selected ?'#8c52ff':'#ffffff' } buttonTextColor={each.QF_SERVICE_CATEGORY_PRID != selected ?'#461c99':'#ffffff'} buttonTitle={each.category_name}/>


           </div>
          )
          }):null} 


          { categoryButtonsServices.length  && ProOrSer == 'Services'  ?
              categoryButtonsServices.map((each,index)=>{

           return(
                  <div  style={{marginBottom:20}}>
                   <CategoriesButton handle={()=>handleButtonClickServices(each.QF_SERVICE_CATEGORY_PRID)} buttonColor={each.QF_SERVICE_CATEGORY_PRID === selected1 ?'#8c52ff':'#ffffff' } buttonTextColor={each.QF_SERVICE_CATEGORY_PRID != selected1 ?'#461c99':'#ffffff'} buttonTitle={each.category_name}/>
             </div>
          )
          }):null} 
           </div>
        
         <div style={{display:'flex',flexWrap:'wrap',justifyContent:'space-between', marginTop:44,width:'100%',overflow:'hidden',gridTemplateColumns:'repeat(auto-fill,184px)'}}>
          { 
          loading ?
          <div style={{ margin:'auto', paddingBottom:'63%'}}>
          <ClipLoader widthUnit={"100%"} size={40} loadings={loading} color="#8c52ff" />
          </div>
          : data 
           }
         </div>
        
         {serviceDetailsModal && serviceDetailsDisplayModal}
         {showModal.Modal && proModal}
         {showModal1.Modal  && serModal}

        </div>
        )

    }
    export default WishList_mobile
