import React, { useState, useEffect, useContext } from 'react';
import Circle from '../../Assets/checkout_info_icon.png';
import Star0 from '../../Assets/rating_rectangle0.png';
import Star1 from '../../Assets/rating_rectangle1.png';
import Star2 from '../../Assets/rating_rectangle2.png';
import Star3 from '../../Assets/rating_rectangle3.png';
import Star4 from '../../Assets/rating_rectangle4.png';
import Star5 from '../../Assets/rating_rectangle5.png';
import Image1 from '../../Assets/rectangle1.png'
import Image2 from '../../Assets/rectangle2.png'
import Image3 from '../../Assets/rectangle3.png'
import Image4 from '../../Assets/rectangle4.png'
import Image5 from '../../Assets/rectangle5.png'
import CouponAppliedIcon from '../../Assets/couponApplySuccessIcon.png';
import { globalState } from '../../GlobalState/globalState'; // Importing global state
// import CategoriesButton from '../../Components/Custom/UI/CategoriesButton';
import SubmitButtonSolid from '../../Components/Custom/UI/submitButtonSolid';
// import HeaderNew from '../../Components/Custom/UI/HeaderNew';
import { useNavigate } from 'react-router-dom';
import customFetchData from '../../Components/Custom/customFetchData';
import '../../CSS/RatingPage.css';
import Close_Cross from '../../Components/Custom/UI/Close_Cross';
import { ToastContainer, toast } from 'react-toastify';
import ResponseModal from '../../Components/Custom/UI/ResponseModal';

let selectedValue = []

function RatingPage(props) {
    const navigate=useNavigate();
    const [feedback, setFeedback] = useState([
        {
            id: 1,
            name: 'Behaviour',
        },
        {
            id: 2,
            name: 'Hygiene',

        },
        {
            id: 3,
            name: 'Price',
        },
        {
            id: 4,
            name: 'Quality',

        },
        {
            id: 5,
            name: 'Ambience',

        },
    ])
    const [suggest, setSuggest] = useState([])
    const [clicked, setClicked] = useState([])
    const [first, setFirst] = useState('')
    const [second, setSecond] = useState('')
    const [third, setThird] = useState('')
    const [fourth, setFourth] = useState('')
    const [fifth, setFifth] = useState('')
    const [data, setData] = useState('')
    const [complaint, setComplaint] = useState('')
    const [params, setParams] = useState('')
    const [bookingPrid, setBookingPrid] = useState('')
    const [state, dispatch] = useContext(globalState)
    const [ratingSuccessModal, setRatingSuccessModal] = useState(false)



    useEffect(() => {
        feedback.map((item, index) => {
            return clicked['clicked_' + item.id] = false
        })
        // console.log('props in rating page', props)
        setBookingPrid(props.prid)
        selectedValue = []

        if (props.rating !== 0) {
            if (props.rating == 1) {
                setFirst(true)
                setParams(true)

            }
            if (props.rating == 2) {
                setSecond(true)
                setParams(true)
            }
            if (props.rating == 3) {
                setThird(true)
                setParams(true)
            }
            if (props.rating == 4) {
                setFourth(true)
                setParams(true)
            }
            if (props.rating == 5) {
                setFifth(true)
                setParams(true)
            }

        }
    }, [])

    const onPressBack = () => {
        // console.log('on press back ')
        navigate.goBack()
    }
    const onCardClick = async (item) => {
        // console.log('item', item)
        feedback.map(item1 => {
            if (item1.id === item.id) {
                // console.log('click', item1.name === item.name)
                setClicked((prevState) => {
                    return {
                        ...prevState,
                        ['clicked_' + item.id]: !clicked['clicked_' + item1.id],
                    }
                })
                // setSuggest(item.name)
                selectedValue.push(item.name)
            }
        })


    }
    const firstHandler = () => {
        setFirst(true)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(false)


    }
    const secondHandler = () => {
        setFirst(false)
        setSecond(true)
        setThird(false)
        setFourth(false)
        setFifth(false)

    }
    const thirdHandler = () => {
        setFirst(false)
        setSecond(false)
        setThird(true)
        setFourth(false)
        setFifth(false)

    }
    const fortHandler = () => {
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(true)
        setFifth(false)

    }
    const fifhHandler = () => {
        setFirst(false)
        setSecond(false)
        setThird(false)
        setFourth(false)
        setFifth(true)

    }
    const complaintHandler = (event) => {
        setComplaint(event.target.value)


    }

    // const onStarPressHandler=async(rating,bookingId)=>{
    //     console.log('ratings',rating)
    //     setStarCount((prevState)=>{
    //         return {
    //         ...prevState,
    //         ['starCount_'+bookingId]:rating
    //     }
    //     })
    //     let ids={
    //         rating:params,
    //         prid:bookingId
    //     }
    //     let ratingData=await useFetchData('POST',ids,'/consumer/rating',state,dispatch)
    //     console.log('data',ratingData)
    //     if(ratingData.fetch){
    //         if(ratingData.fetchedData.submitted){
    //             Toast.show("Thank you for your valuable rating",Toast.SHORT);
    //             // this.props.navigation.navigate('AcceptedOrders');
    //             // setStarCount((prevState)=>{
    //             //     return {
    //             //     ...prevState,
    //             //     ['starCount_'+bookingId]:rating
    //             //     }
    //             // })

    //         }
    //         else
    //         {
    //             if(ratingData.fetchedData.dbError)
    //             {
    //                 Toast.show("Internal error",Toast.SHORT);
    //             }
    //         }
    //     }
    // }
    const submitHandler = async (rating, bookingId) => {
        // const words = new Set(selectedValue);
        // console.log('submit', suggest, complaint, props.ratingStarPrid)
        // console.log('rating', first ? 1 : second ? 2 : third ? 3 : fourth ? 4 : fifth ? 5 : 0)
        let words = new Set(selectedValue);
        let words1 = Array.from(words);

        const myJSON = JSON.stringify(words1);
        // console.log('words1', myJSON)

        let ids = {
            rating: first ? 1 : second ? 2 : third ? 3 : fourth ? 4 : fifth ? 5 : 0,
            prid: bookingPrid,
            suggestion: complaint,
            feedback: myJSON ? myJSON : null
        }
        // console.log('body', ids)
        let ratingData = await customFetchData('POST', ids, '/website/rating/sv2', state, dispatch)
        // console.log('data', ratingData)
        if (ratingData.fetch) {
            if (ratingData.fetchedData.submitted) {
                // toast("Thank you for your valuable rating");
                setRatingSuccessModal(true)
                // this.props.navigation.navigate('AcceptedOrders');
                // setStarCount((prevState)=>{
                //     return {
                //     ...prevState,
                //     ['starCount_'+bookingId]:rating
                //     }
                // })

                setTimeout(() => {
                    setRatingSuccessModal(false)
                    return props.onPress()
                }, 2500)

                // navigate("PastBooking", {});


            }
            else {
                if (ratingData.fetchedData.dbError) {
                    toast("Internal error", {
                        position: "bottom-center",
                        hideProgressBar: true,
                        progress: undefined,
                        style:{backgroundColor:'#E0D1FF'}
                    });
                }
            }
        }
    }

    let feedbackList =  feedback.map((item, index) => {
        return (
            <div onClick={() => onCardClick(item)} className="rating_feedback_list_buttonStyle_style" style={{backgroundColor: clicked['clicked_' + item.id] ? '#8c52ff' : '#ffffff', borderColor: '#bfa0ff',borderRadius: 8}}>
                <text className="textStyle" style={{color:clicked['clicked_' + item.id] ? '#fff' : '#461c99'}}>{item.name}</text>
            </div>
        )
        })

        const preventCopyPaste = (e) => {
            e.preventDefault()
            // toast.info("Copying and pasting is not allowed!", {position: toast.POSITION.BOTTOM_CENTER})
          }

    // console.log('ssss', props.prid)
    return (

        <div className='rating_main_div'>
           <div className="CrossContainer_main_div">
                <Close_Cross onClick={props.onPress} />
            </div>
            
            <div className='rating_inside_div1'>
                <text className="rating">Rate your experience with us!</text>
                <text className="ratingSubText">We’d love to know!</text>
            </div>

            <div className='rating_inside_smiley_div2'>

                <div className='rating_image_star_block_style'>
                    <div onClick={firstHandler}>
                        {first ?
                            <img src={Star1} />
                        : second ?
                            <img src={Star2} />
                        : third ?
                            <img src={Star3} />
                        : fourth ?
                            <img src={Star4} />
                        : fifth ?
                            <img src={Star5} />
                        :
                            <img src={Star0} />
                        }
                    </div>
                    <div onClick={firstHandler} className="rating_smiley_image_div" style={{ backgroundColor: first ? 'pink' : '#eeeeee' }}>
                        <img src={Image1} />
                    </div>
                </div>

                <div className='rating_image_star_block_style'>
                    <div onClick={secondHandler}>
                        {second ?
                            <img src={Star2} />
                        : third ?
                            <img src={Star3} />
                        : fourth ?
                            <img src={Star4} />
                        : fifth ?
                            <img src={Star5} />
                        :
                            <img src={Star0} />
                        }
                    </div>
                    <div onClick={secondHandler} className="rating_smiley_image_div" style={{ backgroundColor: second ? 'pink' : '#eeeeee' }}>
                        <img src={Image2} />
                    </div>
                </div>
                <div className='rating_image_star_block_style'>
                    <div onClick={thirdHandler}>
                        {third ?
                            <img src={Star3} />
                        : fourth ?
                            <img src={Star4} />
                        : fifth ?
                            <img src={Star5} />
                        :
                            <img src={Star0} />
                        }
                    </div>
                    <div onClick={thirdHandler} className="rating_smiley_image_div" style={{ backgroundColor: third ? 'pink' : '#eeeeee' }}>
                        <img src={Image3} />
                    </div>
                </div>
                <div className='rating_image_star_block_style'>
                    <div onClick={fortHandler}>
                        {fourth ?
                            <img src={Star4} />
                        : fifth ?
                            <img src={Star5} />
                        :
                            <img src={Star0} />
                        }
                    </div>
                    <div onClick={fortHandler} className="rating_smiley_image_div" style={{ backgroundColor: fourth ? 'pink' : '#eeeeee' }}>
                        <img src={Image4} />
                    </div>
                </div>
                <div className='rating_image_star_block_style'>
                    <div onClick={fifhHandler}>
                        {fifth ?
                            <img src={Star5} />
                        :
                            <img src={Star0} />
                        }
                    </div>
                    <div onClick={fifhHandler} className="rating_smiley_image_div" style={{ backgroundColor: fifth ? 'pink' : '#eeeeee' }}>
                        <img src={Image5} />
                    </div>
                </div>
            </div>

            {first ?
                <text className="imgText">Hated it!</text>
                : null}
            {second ?
                <text className="imgText">Didn’t like!</text>
                : null}
            {third ?
                <text className="imgText">Was Okay</text>
                : null}
            {fourth ?
                <text className="imgText">Liked it</text>
                : null}
            {fifth ?
                <text className="imgText">Loved it</text>
                : null}

                <div className="rating_line_separator"></div>

            {first ?
                <text className="content">We are so sorry. What was wrong?</text>
                : null}
            {second ?
                <text className="content">Oops. Where we missed?</text>
                : null}
            {third ?
                <text className="content">Neutral is okay. Why is that?</text>
                : null}
            {fourth ?
                <text className="content">Great, where can we improve?</text>
                : null}
            {fifth ?
                <text className="content">Yay! what did you like ?</text>
                : null}

            
            <div className='rating_feedback_list_div_style'>
                {feedbackList}
            </div>
                

            <div className='rating_writeUs_input_field_style'>
                <input
                    type='textArea'
                    className="textArea"
                    underlineColorAndroid="transparent"
                    placeholder="Tell us more"
                    placeholderTextColor="grey"
                    numberOfLines={10}
                    multiline={true}
                    value={complaint}
                    onChange={(e) => complaintHandler(e)}
                    maxLength={200}
                    onPaste={(e) => preventCopyPaste(e)}
                    onCopy={(e) => preventCopyPaste(e)}
                />
            </div>

            {/* <div className={{ flexDirection: 'row', justifyContent: 'space-between', marginTop: 10 }}>
                <div className={{ width: 160, height: 39, borderRadius: 8, borderStyle: "solid", borderWidth: 1, borderColor: "#9f9f9f", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={Camera} />
                    <text className={addphoto}>Add Photo</text>
                </div>
                <div className={{ width: 160, height: 39, borderRadius: 8, borderStyle: "solid", borderWidth: 1, borderColor: "#9f9f9f", flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={Camera} />
                    <text className={addphoto}>Add Vedio</text>
                </div>
            </div> */}
                {/* <div className={{ marginTop: height - 690 }}></div> */}
            <div className='rating_writeUs_button_field_style'>
                <div onClick={submitHandler} className="rating_submit_button_style" style={{backgroundColor: '#8c52ff'}}>
                    <text className="textStyle" style={{color: '#ffffff'}}>Submit</text>
                </div>
            </div>

            {/* <Modal isOpen={ratingSuccessModal} className="modal_align_middle" size="md" centered> */}
            <ResponseModal isOpen={ratingSuccessModal} displayText="Thank you for your valuable rating!" />
                {/* <div style={{padding:20, display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', background:'#e0d1ff', borderRadius:10}}>
                    <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                        <img src={CouponAppliedIcon} width={30} height={30} style={{marginRight:10}}/>
                        <text className="modalCouponSavedText">Thank you for your valuable rating!</text>
                    </div>
                </div> */}
            {/* </Modal> */}

            <ToastContainer />
        </div>
    );
}

export default RatingPage;